import request from '@/utils/request'

const api = {
  user: '/admincp/menu/index',
  usercreate: '/admincp/menu/create',
  userupdate: '/admincp/menu/update',
  userinfo: '/admincp/menu/info',
  userDel: '/admincp/menu/delete',
  userDelete: '/admincp/menu/moredel',
  allList: '/admincp/menu/allList',
  allListTree: '/admincp/menu/allListTree'

}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.usercreate,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userupdate,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDel,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDelete,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function allList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.allList,
    method: 'post',
    data: parameter
  })
}

export function allListTree (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.allListTree,
    method: 'post',
    data: parameter
  })
}
