<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="id"
      >
        <a-input
          v-decorator="['id',{ initialValue:'0' }]"
          name="regionId"></a-input>
      </a-form-item>
      <a-form-item
        label="菜单名称"
      >
        <a-input
          v-decorator="[
            'title',
            {rules: [{ required: true, message: '请输入菜单名称' }]}
          ]"
          name="title"
          placeholder="请输入菜单名称"/>
      </a-form-item>
      <a-form-item
        label="上级菜单"
      >
        <tree-select
          v-decorator="[
            'parentId'
          ]"
          :replaceFields="replaceFields"
          :tree-data="treeData"
          allow-clear
          placeholder="请选择"
        />
      </a-form-item>
      <a-form-item
        :required="false"
        label="模块"
      >
        <a-select
          v-decorator="[
            'moduleId',
            {rules: [{ required: true, message: '请选择模块' }]}
          ]"
          name="moduleId"
        >
          <a-select-option v-for="item in moduledata" :key="item.moduleId" :value="item.moduleId">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        label="菜单名称"
      >
        <a-input
          v-decorator="[
            'component',
            {rules: [{ required: true, message: '请输入Component' }]}
          ]"
          name="component"
          placeholder="请输入Component"/>
      </a-form-item>
      <a-form-item
        label="跳转"
      >
        <a-input
          v-decorator="[
            'redirect',
            {rules: [{ required: false, message: '请输入redirect' }]}
          ]"
          name="redirect"
          placeholder="请输入redirect"/>
      </a-form-item>
      <a-form-item
        label="路径path"
      >
        <a-input
          v-decorator="[
            'path',
            {rules: [{ required: false, message: '请输入path' }]}
          ]"
          name="path"
          placeholder="请输入path"/>
      </a-form-item>

      <a-form-item
        label="icon"
      >
        <a-input
          v-decorator="[
            'icon',
            {rules: [{ message: '请输入icon' }]}
          ]"
          name="icon"
          placeholder="请输入icon"/>
      </a-form-item>

      <a-form-item
        label="itemkey"
      >
        <a-input
          v-decorator="[
            'itemkey',
            {rules: [{ required: false, message: '请输入itemkey' }]}
          ]"
          name="itemkey"
          placeholder="请输入itemkey"/>
      </a-form-item>

      <a-form-item
        label="排序"
      >
        <a-input
          v-decorator="[
            'listorder',
            {rules: [{ required: true, message: '请输入排序' }]}
          ]"
          name="path"
          placeholder="请输入排序"/>
      </a-form-item>

      <a-form-item
        :required="true"
        label="是否隐藏子菜单"
      >
        <a-radio-group v-decorator="['hideChildren', { initialValue: 1 }]" name="hideChildren">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        :required="true"
        label="是否隐藏头部内容"
      >
        <a-radio-group v-decorator="['hiddenHeaderContent', { initialValue: 1 }]" name="hiddenHeaderContent">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        :required="true"
        label="是否保持在线"
      >
        <a-radio-group v-decorator="['keepAlive', { initialValue: 1 }]" name="keepAlive">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="2">否</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        :required="true"
        label="是否显示"
      >
        <a-radio-group v-decorator="['isShow', { initialValue: 1 }]" name="isShow">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="2">否</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        :required="true"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
import { allList } from '@/api/menu'
import { allList as moduleallList } from '@/api/module'

export default {
  components: {
    TreeSelect
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: this.$form.createForm(this),
      treeData: [],
      moduledata: [],
      replaceFields: { children: 'children', title: 'title', key: 'id', value: 'id' }
    }
  },
  mounted () {
    this.getAllList()
    this.moduleList()
  },
  methods: {
    getAllList () {
      return allList()
        .then(res => {
          this.treeData = res.result
        })
    },
    moduleList () {
      return moduleallList()
        .then(res => {
          this.moduledata = res.result
        })
    }
  }
}
</script>
